


import { Component, Vue } from "vue-property-decorator";
import { Login } from "@/shared/dtos/login";
import { UtilsString } from "@/utils/utils-string";
import pacienteModule from "@/store/modules/paciente-module";
import { ThemeConstants } from "@/shared/ThemeConstants";

@Component({
  $_veeValidate: { validator: "new" },
})
export default class LoginFormulario extends Vue {
  //public appTitle = process.env.VUE_APP_TITLE;

  public login!: Login;
  public hide: boolean = true;

  public mounted() {
    //Personalizacion de la aplicacion
    this.personalizarapp();
    this.login = new Login();
    if (localStorage.getItem("login") !== null) {
      this.login = new Login(
        JSON.parse(UtilsString.ValueOf(localStorage.getItem("login")))
      );
      this.submit();
    }
    setTimeout(() => (this.hide = false), 1);
  }

  public submit() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        this.tryLogin();
      }
    });
  }

  public get appTitle() {
    let str = localStorage.getItem("VUE_APP_TITLE");
    if (!UtilsString.IsNullOrWhiteSpace(str)) {
      return UtilsString.ValueOf(str);
    }

    return process.env.VUE_APP_TITLE;
  }

  getStoredColor(key: string, defaultValue: string) {
    const color = window.localStorage.getItem(key);
    return color ? color : defaultValue;
  }

  /**
   * personalizarapp
   */
  public personalizarapp() {
    // Función para obtener los colores del localStorage o asignar valores predeterminados
    ThemeConstants.PRIMARY_COLOR = String(
      this.$vuetify.theme.themes.light.primary
    );
    ThemeConstants.SECONDARY_COLOR = String(
      this.$vuetify.theme.themes.light.secondary
    );
    ThemeConstants.LIGHTER_COLOR = ThemeConstants.getLighterColor(
      ThemeConstants.PRIMARY_COLOR
    );
    ThemeConstants.DARKER_COLOR = ThemeConstants.getLighterColor(
      ThemeConstants.PRIMARY_COLOR,
      0.8
    );

    // Obtenemos los valores desde el localStorage o creamos nuevos valores si no existen
    ThemeConstants.PRIMARY_COLOR = this.getStoredColor(
      "PRIMARY_COLOR",
      ThemeConstants.PRIMARY_COLOR
    );
    ThemeConstants.SECONDARY_COLOR = this.getStoredColor(
      "SECONDARY_COLOR",
      ThemeConstants.SECONDARY_COLOR
    );
    ThemeConstants.LIGHTER_COLOR = this.getStoredColor(
      "LIGHTER_COLOR",
      ThemeConstants.LIGHTER_COLOR
    );
    ThemeConstants.DARKER_COLOR = this.getStoredColor(
      "DARKER_COLOR",
      ThemeConstants.DARKER_COLOR
    );

    ThemeConstants.RGB = ThemeConstants.hexToRgb(ThemeConstants.PRIMARY_COLOR);

    // Aplica los valores como variables CSS globales
    document.documentElement.style.setProperty(
      "--primary",
      ThemeConstants.PRIMARY_COLOR
    );
    document.documentElement.style.setProperty(
      "--secondary",
      ThemeConstants.SECONDARY_COLOR
    );
    document.documentElement.style.setProperty(
      "--primary-rgb",
      ThemeConstants.RGB
    );
    document.documentElement.style.setProperty(
      "--primary-lighter",
      ThemeConstants.LIGHTER_COLOR
    );
    document.documentElement.style.setProperty(
      "--primary-darker",
      ThemeConstants.DARKER_COLOR
    );
  }

  public async tryLogin() {
    const authorizationService = await import(
      "@/shared/services/AuthorizationService"
    );
    authorizationService.authorizationService
      .login(this.login)
      .then(this.loginResult.bind(this));
  }

  private async loginResult(loginOk: boolean) {
    if (loginOk) {
      this.hide = true;
      localStorage.setItem("login", JSON.stringify(this.login));
      setTimeout(() => this.routeToMain(), 250);
    } else {
      const authorizationService = await import(
        "@/shared/services/AuthorizationService"
      );
      authorizationService.authorizationService
        .EsNutricionista(this.login)
        .then(async (x: boolean) => {
          if (!x) {
            const ssmMessageService = await import(
              "@/shared/services/message-service"
            );

            ssmMessageService.ssmMessageService.toast(
              "Usuario o Clave incorrectos",
              ssmMessageService.ssmMessageService.TypeError
            );
          }
        });
    }
  }

  private routeToMain() {
    this.$router.push("/");
  }
}
